const TitleSection = ({ isMobile }) => {
    let textStyle = { fontSize: '30px' };
    let imageStyle;
    if (isMobile) {
        textStyle.textAlign = 'center';
        imageStyle = {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    }
    return (
        <section className="u-align-left u-clearfix u-custom-color-1 u-section-1 title-section added-padding" id="carousel_3e8e" style={{ backgroundColor: '#8cca45' }}>
            <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
                <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
                    <div className="u-gutter-0 u-layout">
                        <div className="u-layout-row">
                            <div className="u-align-left u-container-style u-layout-cell u-left-cell u-shape-rectangle u-size-27 u-layout-cell-1">
                                <div className="u-container-layout u-valign-middle u-container-layout-1">
                                    <img className='junglo-logo' src="images/junglo-logo.png" alt="" style={ imageStyle } />
                                    <p className="u-text u-text-body-alt-color u-text-2" style={ textStyle }>A collaboration catalyzing community for stock traders</p>
                                </div>
                            </div>
                            <div className="u-align-left u-container-style u-image u-layout-cell u-right-cell u-size-33 u-image-1" data-image-width="1080" data-image-height="1080">
                                <div className="u-container-layout u-valign-bottom u-container-layout-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TitleSection