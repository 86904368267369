import { useState, useEffect } from 'react';

import TitleSection from '../components/TitleSection.js';
import AboutJunglo from '../components/AboutJunglo.js';
import JourneySteps from '../components/JourneySteps.js';
import AnimalProfiles from '../components/AnimalProfiles.js';
import AppplicationCarousel from '../components/ApplicationCarousel.js';
import AppInfo from '../components/AppInfo.js';
import AppLaunchInfo from '../components/AppLaunchInfo.js';
import JungloTeam from '../components/JungloTeam.js';
import FooterSection from '../components/FooterSection.js';

const HomeView = () => {
    const [width, setWidth] = useState(window.innerWidth);
        function handleWindowSizeChange() {
                setWidth(window.innerWidth);
        }

    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width <= 768);

    return (
        <>
            <TitleSection isMobile={ isMobile } />
            <AboutJunglo isMobile={ isMobile } />
            <JourneySteps />
            <AnimalProfiles isMobile={ isMobile } />
            <AppplicationCarousel isMobile={ isMobile } />
            <AppInfo isMobile={ isMobile } />
            {/* <AppLaunchInfo isMobile={ isMobile }/> */}
            <JungloTeam isMobile={ isMobile } />
            <FooterSection />
        </>
    )
}

export default HomeView;