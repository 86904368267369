import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';

const JourneySteps = () => {
    return (
      <section className="u-align-center u-clearfix u-custom-color-1 u-section-9 added-padding" id="carousel_7a8d">
        <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
          <h1 style={{ fontWeight: 'bold' }}>Junglo's 3 Step Journey</h1>
          <br/>
          <div className="u-clearfix u-gutter-30 u-layout-wrap u-layout-wrap-1">
            <div className="u-gutter-0 u-layout">
              <div className="u-layout-col">
                <div className="u-size-60">
                </div>
              </div>
            </div>
            <CardDeck>
              <Card style={{backgroundColor: '#22E147', borderStyle: 'none'}}>
                <Card.Body>
                  <h2>Step 1</h2>
                  <h4>Pick your animal</h4>
                  <p>6 cute investing archetypes</p>
                </Card.Body>
              </Card>
              <Card style={{backgroundColor: '#1EBF3D', borderStyle: 'none'}}>
                <Card.Body>
                  <h2>Step 2</h2>
                  <h4>Drop sentiment on charts</h4>
                  <p>Pin your future projection</p>
                </Card.Body>
              </Card>
              <Card style={{backgroundColor: '#18932F', borderStyle: 'none'}}>
                <Card.Body>
                  <h2>Step 3</h2>
                  <h4>2 different graphs: real and expectation</h4>
                  <p>Your idea makes the line</p>
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
        </div>
      </section>
    )
}

export default JourneySteps