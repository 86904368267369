const AppInfo = ({ isMobile }) => {
  let innerTextStyle = {
    fontWeight: 'bold'
  }
  if (isMobile) {
    innerTextStyle.textAlign = 'center';
  }
  return ( 
    <section className="u-clearfix u-white u-section-8 added-padding" id="sec-d1be">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-container-style u-layout-cell u-right-cell u-size-30 u-layout-cell-2">
                <img className='campfire-image' src='images/app-info.png' alt="info" width='100%' height="auto" />
              </div>
              <div className="u-container-style u-layout-cell u-left-cell u-size-30 u-layout-cell-1 about-junglo-text">
                <div className="u-container-layout u-valign-top u-container-layout-1">
                  <h2 className='info-inner-title' style={ innerTextStyle }>Gather Around By (x,y) <br />and make the new 'z'</h2>
                  <p className='info-inner-text'>See what the Junglo community of animals is predicting in their charts.<br />
                  Their comments may make you laugh, or have you exclaim “Eureka”!<br />
                  Who wouldn’t want to come join this amicable, insightful, and tropical haven.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AppInfo