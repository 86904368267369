const JungloTeam = ({ isMobile }) => {
  let teamPage;
  let imageStyle;
  if (isMobile) {
    imageStyle = { width: '150px' };
    teamPage = 
      <section className='team-page added-padding'>
        <h1 className='team-title' style={{ fontWeight: 'bold' }}>Team Junglo</h1>
        <div className='team-members-mobile'>
          <div className='team-rows-mobile'>
            <img className='individual-member noclick-image' alt='' style={ imageStyle } src='./images/AndyOh.png' />
            <div className='name-text'>Andy Oh</div>
            <div className='title-text'>CEO, Co-Founder</div>
          </div>
          <div className='team-rows-mobile'>
            <img className='individual-member noclick-image' alt='' style={ imageStyle } src='./images/SeungwooPark.png' />
            <div className='name-text'>Seungwoo Park</div>
            <div className='title-text'>CMO, Co-Founder</div>
          </div>
          <div className='team-rows-mobile'>
            <img className='individual-member noclick-image' alt='' style={ imageStyle } src='./images/MoonKang.jpg' />
            <div className='name-text'>Moon Kang</div>
            <div className='title-text'>CTO</div>
          </div>
          <div className='team-rows-mobile'>
            <img className='individual-member noclick-image' alt='' style={ imageStyle } src='./images/YoonhoBang.png' />
            <div className='name-text'>Yoonho Bang</div>
            <div className='title-text'>UX Designer</div>
          </div>
          <div className='team-rows-mobile'>
            <img className='individual-member noclick-image' alt='' style={ imageStyle } src='./images/JihoonKwak.png' />
            <div className='name-text'>Jihoon Kwak</div>
            <div className='title-text'>Front End Developer</div>
          </div>
        </div>
    </section>
  } else {
    imageStyle = { width: '50%' };
    teamPage = 
      <section className='team-page added-padding'>
        <h1 className='team-title' style={{ fontWeight: 'bold' }}>Team Junglo</h1>
        <div className='team-members'>
          <div className='team-rows andy-div'>
            <img className='individual-member noclick-image' alt='' style={ imageStyle } src='./images/AndyOh.png' />
            <div className='name-text'>Andy Oh</div>
            <div className='title-text'>CEO, Co-Founder</div>
          </div>
          <div className='team-rows seungwoo-div'>
            <img className='individual-member noclick-image' alt='' style={ imageStyle } src='./images/SeungwooPark.png' />
            <div className='name-text'>Seungwoo Park</div>
            <div className='title-text'>CMO, Co-Founder</div>
          </div>
          <div className='team-rows moon-div'>
            <img className='individual-member noclick-image' alt='' style={ imageStyle } src='./images/MoonKang.jpg' />
            <div className='name-text'>Moon Kang</div>
            <div className='title-text'>CTO</div>
          </div>
          <div className='team-rows yoonho-div'>
            <img className='individual-member noclick-image' alt='' style={ imageStyle } src='./images/YoonhoBang.png' />
            <div className='name-text'>Yoonho Bang</div>
            <div className='title-text'>UX Designer</div>
          </div>
          <div className='team-rows jihoon-div'>
            <img className='individual-member noclick-image' alt='' style={ imageStyle } src='./images/JihoonKwak.png' />
            <div className='name-text'>Jihoon Kwak</div>
            <div className='title-text'>Front End Developer</div>
          </div>
        </div>
        {/* <div className='team-members-middle'>
        </div> */}
        {/* <div className='team-members'>
          <div className='team-rows'>
            <img className='individual-member noclick-image' alt='' style={ imageStyle } src='./images/BrianKim.png' />
            <div className='name-text'>Brian Kim</div>
            <div className='title-text'>Front End Developer</div>
          </div>
        </div> */}
    </section>
  }
  return (
    <>
      { teamPage }
    </>
  )
}

export default JungloTeam;