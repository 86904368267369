const AboutJunglo = ({ isMobile }) => {
  let titleStyle;
  if (isMobile) {
    titleStyle = { textAlign: 'center' };
  }
  return ( 
    <section className="u-clearfix u-white u-section-8 added-padding" id="sec-d1be">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-container-style u-layout-cell u-left-cell u-size-30 u-layout-cell-1 about-junglo-text">
                <div className="u-container-layout u-valign-top u-container-layout-1">
                  <h1 className="u-text u-text-body-color u-text-1 section-title" style= { titleStyle }>Enter the Junglo</h1>
                  <br />
                  <br />
                  <div>Investing can feel like being lost in a Jungle. Whether you are facing a troop of mosquitos, or a market crash, outcomes always improve when hardships are navigated with others.</div>
                  <br />
                  <div>Junglo is a collaborative community which aggregates market sentiment, and encourages information sharing across equity traders.</div>
                  <br />
                  <div>So let's make a bonfire, and talk strategy. Tomorrow is a new day, and together we can explore brave new worlds.</div>
                </div>
              </div>
              <div className="u-container-style u-layout-cell u-right-cell u-size-30 u-layout-cell-2">
                <img className='campfire-image' src="images/campfire-animals.png" alt="" width='100%' height="auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutJunglo