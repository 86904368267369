import './App.css';
import HomeView from './views/HomeView'

function App() {
  return (
    <div className="App">
      {/* <img src={logo} className="App-logo" alt="logo" /> */}
      <HomeView />
    </div>
  );
}

export default App;
