import AnimalCarousel from './AnimalCarousel';

const AnimalProfiles = ({ isMobile }) => {
    return (
        <section className="u-align-center u-clearfix u-white carousel-container added-padding" id="carousel_7a94">
            <div className="u-clearfix u-sheet u-valign-middle-md u-valign-middle-sm u-valign-middle-xs u-sheet-1">
                <h1 className="u-align-center u-text u-text-1 info-inner-title" style={{ fontWeight: 'bold' }}>Animal Profiles</h1>
                <br />
                <AnimalCarousel isMobile={ isMobile }/>
            </div>
        </section>
    )
}

export default AnimalProfiles;