import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';

const AnimalCarousel = ({ isMobile }) => {
  let animalCardCarousel;
  if (isMobile) {
    animalCardCarousel = 
      <Carousel>
        <Carousel.Item interval={2000}>
            <Card>
                <Card.Img className='noclick-image' src="images/chameleon.png" alt='chameleon' /> 
            </Card>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
            <Card>
                <Card.Img className='noclick-image' src="images/lion.png" alt='lion' /> 
            </Card>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
            <Card>
                <Card.Img className='noclick-image' src="images/gorilla.png" alt='gorilla' /> 
            </Card>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
            <Card>
                <Card.Img className='noclick-image' src="images/parrot.png" alt='parrot' /> 
            </Card>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
            <Card>
                <Card.Img className='noclick-image' src="images/giraffe.png" alt='giraffe' /> 
            </Card>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
            <Card>
                <Card.Img className='noclick-image' src="images/elephant.png" alt='elephant' /> 
            </Card>
        </Carousel.Item>
      </Carousel>
  } else {
    animalCardCarousel = 
      <Carousel>
        <Carousel.Item interval={2000}>
            <CardDeck>
                <Card>
                    <Card.Img className='noclick-image' src="images/chameleon.png" alt='chameleon' /> 
                </Card>
                <Card>
                    <Card.Img className='noclick-image' src="images/lion.png" alt='lion' /> 
                </Card>
                <Card>
                    <Card.Img className='noclick-image' src="images/gorilla.png" alt='gorilla' /> 
                </Card>
            </CardDeck>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
            <CardDeck>
                <Card>
                    <Card.Img className='noclick-image' src="images/parrot.png" alt='parrot' /> 
                </Card>
                <Card>
                    <Card.Img className='noclick-image' src="images/giraffe.png" alt='giraffe' /> 
                </Card>
                <Card>
                    <Card.Img className='noclick-image' src="images/elephant.png" alt='elephant' /> 
                </Card>
            </CardDeck>
        </Carousel.Item>
      </Carousel>
  }
  return (
    <>
      { animalCardCarousel }
    </>
  )
}

export default AnimalCarousel;