import Carousel from 'react-bootstrap/Carousel';

const ApplicationCarousel = ({ isMobile }) => {
    let carouselTextStyle = {
        fontWeight: 'bold',
        color: '#8BCA45',

    };
    if (isMobile) {
        carouselTextStyle.textAlign= 'center';
    }
    return ( 
        <section className="u-clearfix u-section-8" style={{ backgroundColor: "#F5F5F5" }} id="sec-d1be">
            <div className="u-clearfix u-sheet u-sheet-1">
                <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
                <div className="u-layout">
                    <div className="u-layout-row">
                    <div className="u-container-style u-layout-cell u-left-cell u-size-30 u-layout-cell-1 about-junglo-text">
                        <div className="u-container-layout u-valign-top u-container-layout-1">
                            <h2 style={ carouselTextStyle }>Drop, Join, Contribute</h2>
                            <p>Your opinion matters.<br />Pin your future projection on its chart.<br />Watch in real-time how your point-of-view<br />impacts the community sentiment.</p>
                        </div>
                    </div>
                    <div className="u-layout-cell u-size-30">
                        <Carousel className="u-expanded-width-md u-image u-image-contain u-image-1">
                            <Carousel.Item interval={5000}>
                                <img
                                className="carousel-application"
                                src="images/1.png"
                                alt='1'
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={5000}>
                                <img
                                className="carousel-application"
                                src="images/2.png"
                                alt='2'
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={5000}>
                                <img
                                className="carousel-application"
                                src="images/3.png"
                                alt='3'
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={5000}>
                                <img
                                className="carousel-application"
                                src="images/4.png"
                                alt='4'
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={5000}>
                                <img
                                className="carousel-application"
                                src="images/5.png"
                                alt='5'
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={5000}>
                                <img
                                className="carousel-application"
                                src="images/6.png"
                                alt='6'
                                />
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    )
}

export default ApplicationCarousel;